<script setup>
import router from "@/router";
import store from "@/store";
import { computed, onMounted, ref, defineProps } from "vue";

const props = defineProps({
  reversion: String,
});

const loading = ref(true);

const reversion = ref(null);

const getReversionSummary = async () => {
  loading.value = true;

  let req = await store.dispatch("auth/getReversionSummary", { reversion_string: props.reversion });

  if (req) {
    reversion.value = req;
  } else {
    router.push({ name: "Profile" });
  }

  loading.value = false;
};

const verifyDetailUndo = async () => {
  let req;
  loading.value = true;
  console.log(props.verification);
  try {
    req = await store.dispatch("auth/undoVerifyDetail", { reversion_string: props.reversion });
  } catch (e) {
    console.log(e);
  }

  try {
    await store.dispatch("auth/getUser");
  } catch (e) {
    console.log(e);
  }

  getReversionSummary();

  loading.value = false;

  console.log(req);

  // router.push({ name: "Home" });
};

onMounted(() => {
  //   tryLogin();
  getReversionSummary();
});
</script>

<template>
  <div class="container">
    <div class="my-4 p-8 border-lg bg-gray-100 border-gray-500 rounded-lg border">
      <div class="text-left">
        <div class="mb-4">
          <h1>Would you like to undo the following account change?</h1>
        </div>
        <div v-if="reversion" class="mb-4">
          <div><strong>Type:</strong> {{ reversion.type }}</div>
          <div><strong>Field:</strong> {{ reversion.field }}</div>
          <div v-if="reversion.field !== 'password'">
            <strong>Original value:</strong> {{ reversion.from_value }}
          </div>
          <div v-if="reversion.field !== 'password'">
            <strong>New value:</strong> {{ reversion.to_value }}
          </div>
          <div><strong>Confirmed at:</strong> {{ reversion.applied_at }}</div>
        </div>
        <div class="">
          <button
            @click="verifyDetailUndo"
            :disabled="loading || reversion.reverted_at"
            class="btn"
          >
            {{ loading ? "Loading" : "Revert this change" }}
          </button>
        </div>
        <div class="mt-4" v-if="reversion?.reverted_at">
          <span class="text-red-500">This change was reverted</span>
        </div>
      </div>
    </div>
    <div class="w-full text-center">
      <router-link :to="{ name: 'Home' }" class="text-link">Go to Home</router-link> |
      <router-link :to="{ name: 'Profile' }" class="text-link">To go Profile</router-link>
    </div>
  </div>
</template>
