<script setup>
import store from "@/store";
import { computed, reactive, ref, watch, defineProps } from "vue";
import LoadUser from "@/components/LoadUser.vue";
import NavigationNew from "@/components/NavigationNew.vue";
import router from "@/router";
import { DateTime } from "luxon";

const props = defineProps({
  from: String,
});

const user = computed(() => {
  return store.state.auth.user;
});

const loading = ref(false);

const signupForm = reactive({
  email: null,
  password: null,
});

const loginForm = reactive({
  email: null,
  password: null,
});

const resetForm = reactive({
  email: null,
});

watch(user, (newVal, oldVal) => {
  if (newVal !== null) {
    if (props.from) {
      router.push({ path: decodeURI(props.from) });
    } else {
      router.push({ name: "Profile" });
    }
  }
});

// const signup = async () => {
//   await store.dispatch("auth/updateUsername", usernameForm);
// };

const formatDate = (date) => {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
};

const formatNumber = (num) => {
  return num.toLocaleString("en-US");
};

const mode = ref("login");
const setMode = (v) => {
  mode.value = v;
};

const loginWallet = async () => {
  loading.value = true;
  await store.dispatch("auth/login");
  loading.value = false;
};

const signupWallet = async () => {
  loading.value = true;
  await store.dispatch("auth/signup");
  loading.value = false;
};

const loginPassword = async () => {
  loading.value = true;
  await store.dispatch("auth/loginPassword", {
    email: loginForm.email,
    password: loginForm.password,
  });
  loading.value = false;
};

const signupPassword = async () => {
  loading.value = true;
  await store.dispatch("auth/signupPassword", {
    email: signupForm.email,
    password: signupForm.password,
  });
  loading.value = false;
};

const resetPassword = async () => {
  loading.value = true;
  await store.dispatch("auth/triggerPasswordReset", resetForm.email);
  loading.value = false;
};
</script>

<template>
  <!-- <LoadUser> -->
  <div
    class="h-full bg-cover flex items-center justify-center bg-center relative flex-col"
    :style="{
      'background-image': `url('${require('@/assets/backgrounds/vault-v2.jpg')}')`,
    }"
  >
    <NavigationNew mode="light" />

    <div class="flex-grow flex items-center justify-center w-full relative z-20">
      <div class="p-8 w-full max-w-lg bg-white rounded-xl box-shadow-std">
        <div class="mb-8 text-center"><h1 class="font-black font-brother">Login</h1></div>
        <div class="border-gray-200 mb-4 flex items-center justify-center">
          <button @click="loginWallet" class="btn">Login with Web3 Wallet</button>
        </div>
        <div class="w-full flex items-center justify-center mb-4">
          <div class="border-b border-gray-600 flex-grow"></div>
          <div class="p-4">or</div>
          <div class="border-b border-gray-600 flex-grow"></div>
        </div>
        <div class="border-b border-gray-200 grid grid-cols-1 gap-4 mb-4">
          <div class="form-group">
            <label>Email</label>
            <div class="input-group">
              <input v-model="loginForm.email" class="input" type="text" name="loginname" />
            </div>
          </div>
          <div class="form-group">
            <label>Password</label>
            <div class="input-group">
              <input
                v-model="loginForm.password"
                class="input"
                type="password"
                name="loginpassword"
              />
            </div>
          </div>
          <button @click="loginPassword" class="btn">Login with Email/Password</button>
          <div class="text-center mb-6">
            <router-link :to="{ name: 'ForgotPassword' }" class="text-link text-xs text-link-dark">
              Forgot password? Click here to reset
            </router-link>
          </div>
        </div>
        <div class="mb-4 text-center">
          <router-link :to="{ name: 'SignUp', query: { from: props?.from } }" class="text-link">
            Need an account? <br />Click here to sign up
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <!-- </LoadUser> -->
</template>
