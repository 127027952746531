<script setup>
import store from "@/store";
import { computed, reactive, ref, watch, defineProps } from "vue";
import NavigationNew from "@/components/NavigationNew.vue";
import router from "@/router";

const props = defineProps({
  from: String,
});

const user = computed(() => {
  return store.state.auth.user;
});

const loading = ref(false);

const signupForm = reactive({
  email: null,
  password: null,
});

watch(user, (newVal, oldVal) => {
  if (newVal !== null) {
    if (props.from) {
      router.push({ path: decodeURI(props.from) });
    } else {
      router.push({ name: "Profile" });
    }
  }
});

const signupWallet = async () => {
  loading.value = true;
  await store.dispatch("auth/signup");
  loading.value = false;
};

const successSignup = ref(false);

const signupPassword = async () => {
  loading.value = true;
  let req = await store.dispatch("auth/signupPassword", {
    email: signupForm.email,
    password: signupForm.password,
  });
  loading.value = false;
  if (req) {
    successSignup.value = true;
  }
};
</script>

<template>
  <div
    class="h-full bg-cover flex items-center justify-center bg-center relative flex-col"
    :style="{
      'background-image': `url('${require('@/assets/backgrounds/signup-v1.jpg')}')`,
    }"
  >
    <NavigationNew mode="light" />

    <div class="flex-grow flex items-center justify-center w-full relative z-20">
      <div class="p-8 w-full max-w-lg bg-white rounded-xl box-shadow-std">
        <div class="mb-8 text-center"><h1 class="font-brother font-black">Sign Up</h1></div>
        <template v-if="!successSignup">
          <div class="border-gray-200 mb-4 flex items-center justify-center">
            <button @click="signupWallet" class="btn">Sign up with Web3 Wallet</button>
          </div>
          <div class="w-full flex items-center justify-center mb-4">
            <div class="border-b border-gray-200 flex-grow"></div>
            <div class="p-4">or</div>
            <div class="border-b border-gray-200 flex-grow"></div>
          </div>
          <div class="border-b border-gray-200 grid grid-cols-1 gap-4 mb-4">
            <div class="form-group">
              <label>Email</label>
              <div class="input-group">
                <input v-model="signupForm.email" class="input" type="text" name="signupemail" />
              </div>
            </div>
            <div class="form-group">
              <label>Password</label>
              <div class="input-group">
                <input
                  v-model="signupForm.password"
                  class="input"
                  type="password"
                  name="signuppassword"
                />
              </div>
            </div>
            <button @click="signupPassword" class="btn">Sign up with email / password</button>
          </div>
          <div class="mb-4 text-center">
            <router-link class="text-link" :to="{ name: 'Login', query: { from: props.from } }">
              Already have an account? <br />Click here to log in
            </router-link>
          </div>
        </template>
        <template v-else>
          <div class="text-center font-bold text-lg">
            Successfully signed up - check your email to confirm your account!
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
