<script setup>
import router from "@/router";
import store from "@/store";
import { computed, onMounted, ref, defineProps, watch, reactive } from "vue";

const user = computed(() => {
  return store.state.auth.user;
});

const props = defineProps({
  verification: String,
});

const loading = ref(true);

const successMessage = ref(null);

const confirmPassword = async () => {
  let req;
  loading.value = true;

  if (passwordForm.password !== passwordForm.password_confirm) {
    store.dispatch("toasts/add", {
      message: "Password fields must match",
      variant: "error",
      timeout: 3000,
    });
    return;
  }

  try {
    req = await store.dispatch("auth/verifyDetail", {
      verification_string: props.verification,
      password: passwordForm.password,
    });
  } catch (e) {
    console.error(e);
  }

  try {
    await store.dispatch("auth/getUser");
  } catch (e) {
    console.error(e);
  }

  loading.value = false;
};

const type = ref("password");
const toggleType = () => {
  if (type.value === "password") {
    type.value = "text";
  } else {
    type.value = "password";
  }
};
onMounted(async () => {
  //   await verifyDetail();
});

watch(user, (newVal, oldVal) => {
  if (newVal !== null) {
    router.push({ name: "Profile" });
  }
});

const passwordForm = reactive({
  password: null,
  password_confirm: null,
});
</script>

<template>
  <div class="flex items-center justify-center flex-col">
    <div class="my-4 p-8 border-lg bg-gray-100 border-gray-500 rounded-lg border w-full max-w-md">
      <div class="text-left">
        <h1 class="mb-4">Choose a new password</h1>
        <div class="form-group">
          <label>Password</label>
          <div class="input-group">
            <input class="input" name="password" :type="type" v-model="passwordForm.password" />
          </div>
        </div>

        <div class="form-group">
          <label>Confirm Password</label>
          <div class="input-group">
            <input
              class="input"
              name="password_confirm"
              :type="type"
              v-model="passwordForm.password_confirm"
            />
          </div>
        </div>

        <div class="mb-4">
          <div class="text-sm text-link" @click="toggleType">
            {{ type === "text" ? "Hide" : "Show" }} passwords
          </div>
        </div>

        <div class="">
          <button @click="confirmPassword" type="button" class="btn">Submit</button>
        </div>

        <div>{{ successMessage }}</div>
      </div>
    </div>

    <div class="w-full text-center">
      <router-link :to="{ name: 'Home' }" class="text-link">Go to Home</router-link> |
      <router-link :to="{ name: 'Profile' }" class="text-link">To go Profile</router-link>
    </div>
  </div>
</template>
