<script setup>
import store from "@/store";
import { computed, reactive, ref, watch } from "vue";
import NavigationNew from "@/components/NavigationNew.vue";
import router from "@/router";

const user = computed(() => {
  return store.state.auth.user;
});

const loading = ref(false);

const resetForm = reactive({
  email: null,
});

watch(user, (newVal, oldVal) => {
  if (newVal !== null) {
    router.push({ name: "Profile" });
  }
});

const resetPassword = async () => {
  loading.value = true;
  await store.dispatch("auth/triggerPasswordReset", resetForm.email);
  loading.value = false;
};
</script>

<template>
  <div
    class="h-full bg-cover flex items-center justify-center bg-center relative flex-col"
    :style="{
      'background-image': `url('${require('@/assets/backgrounds/vault-v2.jpg')}')`,
    }"
  >
    <NavigationNew mode="light" />

    <div class="flex-grow flex items-center justify-center w-full relative z-20">
      <div class="p-8 w-full max-w-lg bg-white rounded-xl box-shadow-std">
        <div class="mb-8 text-center"><h1 class="font-black font-brother">Forgot Password</h1></div>
        <div class="border-b border-gray-200 grid grid-cols-1 gap-4 mb-4">
          <div class="form-group">
            <label>Email</label>
            <div class="input-group">
              <input v-model="resetForm.email" class="input" type="text" name="email" />
            </div>
          </div>
          <button @click="resetPassword" class="btn">Reset password</button>
        </div>
        <div class="mb-4 text-center">
          <router-link :to="{ name: 'Login' }" class="text-link"> Back to login </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
