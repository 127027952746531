<script setup>
import router from "@/router";
import store from "@/store";
import { computed, onMounted, ref, defineProps } from "vue";

const props = defineProps({
  token: String,
});

const loading = ref(true);

const tryLogin = async () => {
  let req;
  loading.value = true;
  try {
    req = await store.dispatch("auth/loginToken", props.token);
  } catch (e) {
    console.error(e);
  }
  loading.value = false;

  router.push({ name: "Profile" });
};

onMounted(() => {
  tryLogin();
});
</script>

<template>
  <div class="container">
    <div class="my-4 p-8 border-lg bg-gray-100 border-gray-500 rounded-lg border">
      <div class="text-center">
        <h1>Checking Token...</h1>
      </div>
    </div>
  </div>
</template>
