<script setup>
import router from "@/router";
import store from "@/store";
import { computed, onMounted, ref, defineProps, watch } from "vue";

const user = computed(() => {
  return store.state.auth.user;
});

const props = defineProps({
  verification: String,
});

const loading = ref(true);

const successMessage = ref(null);

const verifyDetail = async () => {
  let req;
  loading.value = true;
  console.log(props.verification);
  try {
    req = await store.dispatch("auth/verifyDetail", { verification_string: props.verification });
  } catch (e) {
    console.log(e);
  }

  try {
    await store.dispatch("auth/getUser");
  } catch (e) {
    console.log(e);
  }

  loading.value = false;

  if (req) {
    successMessage.value = [...(req ?? "Successfully confirmed account change")];
  }

  if (user.value) {
    router.push({ name: "Profile" });
  }

  console.log(req);

  // router.push({ name: "Home" });
};

onMounted(async () => {
  await verifyDetail();
});

watch(user, (newVal, oldVal) => {
  if (oldVal === null && newVal !== null) {
    // store.dispatch("toasts/add", {
    //   message: "Account updated!",
    //   variant: "success",
    //   timeout: 6000,
    // });
    router.push({ name: "Profile" });
  }
});
</script>

<template>
  <div class="flex flex-col items-center justify-center">
    <div class="my-4 p-8 border-lg bg-gray-100 border-gray-500 rounded-lg border w-full max-w-md">
      <div class="text-left">
        <h1 class="mb-4">Verifying Detail...</h1>
        <div v-if="successMessage">{{ successMessage }}</div>
      </div>
    </div>
  </div>
</template>
