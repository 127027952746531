<script setup>
import store from "@/store";
import { computed, reactive, ref, watch } from "vue";
import LoadUser from "@/components/LoadUser.vue";
import Navigation from "@/components/Navigation.vue";
import NavigationNew from "@/components/NavigationNew.vue";
import router from "@/router";
import { DateTime } from "luxon";
import InputCopyClipboard from "@/components/InputCopyClipboard.vue";
import TermsAcceptModal from "@/components/TermsAcceptModal.vue";

const user = computed(() => {
  return store.state.auth.user;
});

const userWallets = computed(() => {
  let collectionWallets = [
    ...(user.value?.wallets ?? []),
    ...(user.value?.custodial_wallets ?? []),
  ];
  console.log({ collectionWallets });
  return collectionWallets;
});

const loading = ref(false);
const loginLink = ref(null);

const getLink = async () => {
  let req;
  loading.value = true;
  try {
    req = await store.dispatch("auth/authToken");
    loginLink.value = req.url;
  } catch (e) {
    console.log(e);
  }
  loading.value = false;

  // console.log(req);
};

const usernameForm = reactive({
  name: user.value?.name,
});

watch(user, (newVal, oldVal) => {
  if (newVal === null) {
    router.push({ name: "Login", query: { from: encodeURI("/profile") } });
  }
  usernameForm.name = newVal?.name;
  updateEmailForm.email = newVal?.email;
});

const updateUsername = async () => {
  await store.dispatch("auth/updateUsername", usernameForm);
};

const addWallet = async () => {
  await store.dispatch("auth/addWallet");
};

const removeWallet = async (address) => {
  await store.dispatch("auth/removeWallet", { address });
};

const makePrimaryWallet = async (address) => {
  await store.dispatch("auth/makePrimaryWallet", { address });
};

const updatePasswordForm = reactive({
  show: false,
  password: null,
  password_new: null,
  type: "password",
});

const cancelPasswordChange = () => {
  updatePasswordForm.show = false;
  updatePasswordForm.password = null;
  updatePasswordForm.password_new = null;
};

const updatePassword = async () => {
  loading.value = true;
  let pswreq = await store.dispatch("auth/updatePassword", updatePasswordForm);
  if (pswreq) {
    updatePasswordForm.show = false;
    updatePasswordForm.password = null;
    updatePasswordForm.password_new = null;
  }
  loading.value = false;
};

const updateEmailForm = reactive({
  show: false,
  email: user.value?.email,
});

const cancelEmailChange = () => {
  updateEmailForm.show = false;
  updateEmailForm.email = user.value?.email;
};

const updateEmail = async () => {
  loading.value = true;
  let emlreq = await store.dispatch("auth/updateEmail", updateEmailForm);
  if (emlreq) {
    updateEmailForm.show = false;
    // updateEmailForm.email = user.value.email;
  }
  loading.value = false;
};

const initialSetForm = reactive({
  show: false,
  email: null,
  password: null,
  type: "password",
});

const cancelInitialSetEmailPassword = () => {
  initialSetForm.show = false;
  initialSetForm.email = null;
  initialSetForm.password = null;
};

const initialSetEmailPassword = async () => {
  // if (initialSetForm.password !== initialSetForm.password_confirm) {
  //   store.dispatch("toasts/add", { message: "Passwords must match", variant: "error" });
  //   return;
  // }
  loading.value = true;
  let setupreq = await store.dispatch("auth/initialSetEmailPassword", initialSetForm);
  if (setupreq) {
    initialSetForm.show = false;
  }
  loading.value = false;
};

const formatDate = (date) => {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
};

const formatNumber = (num) => {
  return num.toLocaleString("en-US");
};

const pluralize = (word, quantity) => {
  if (quantity === 1) {
    switch (word) {
      case "chip_bonus":
        return "bonus chip";
      default:
        return "credits";
    }
  }
  switch (word) {
    case "chip":
      return "chips";
    case "chip_bonus":
      return "bonus chips";
    case "credit":
      return "credits";
  }

  return word;
};
</script>

<template>
  <LoadUser :from="'/profile'">
    <NavigationNew />
    <div class="container">
      <!-- <div class="my-4">
        <router-link :to="{ name: 'Poker' }">&lt; Back to Game</router-link>
      </div> -->
      <div class="py-12 grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div class="left-col">
          <div class="mb-4 pb-4 border-b border-gray-500">
            <h1>Your Profile</h1>
          </div>
          <div class="flex flex-col mb-8">
            <label class="text-gray-700 mb-1">Verification Status</label>
            <div class="text-gray-500 text-sm italic mb-2">
              You are: {{ user?.user_verified_at ? "VERIFIED" : "Not verified" }}
            </div>
          </div>
          <div class="flex flex-col mb-8">
            <label class="text-gray-700 mb-1">Display Name</label>
            <div class="text-gray-500 text-sm italic mb-2">
              Will be either your username (if set), or your default wallet resolved ENS name, or
              your default wallet address
            </div>
            <input
              type="text"
              :value="user?.username"
              disabled="disabled"
              class="border-gray-400 border p-4 rounded"
            />
          </div>
          <div class="flex flex-col mb-8">
            <label class="text-gray-700 mb-1">Name</label>
            <div class="text-gray-500 text-sm italic mb-2">Set your username - can be empty.</div>
            <form @submit.prevent="updateUsername" class="w-full">
              <div class="w-full mb-2">
                <input
                  type="text"
                  v-model="usernameForm.name"
                  class="border-gray-400 border p-4 rounded w-full"
                  placeholder="e.g. HodlMaster1234"
                />
              </div>
              <div>
                <button class="btn btn-sm" :disabled="loading" type="submit">
                  Update username
                </button>
              </div>
            </form>
          </div>

          <div class="mb-4 pb-4 border-b border-gray-500">
            <h1>Web3 Wallets</h1>
          </div>
          <div class="flex flex-col mb-8">
            <label class="text-gray-700 mb-1">Wallets</label>
            <div class="text-gray-500 text-sm italic mb-2">
              You can link multiple wallets to your Society of the Hourglass account. You can then
              log in with any of the associated wallets.
            </div>
            <div class="text-red-400 text-sm italic mb-2">
              NOTE: Linking a wallet already associated with another account will merge that account
              into this one along with wallets from that other account - BUT ARCADE POINTS AND
              GAMEPLAY HISTORY WILL NOT TRANSFER.
            </div>
            <div class="mb-2">
              <button class="btn btn-sm btn-green" @click="addWallet" :disabled="loading">
                Add wallet
              </button>
            </div>
            <div>
              <div
                v-for="(wallet, w) in userWallets"
                :key="w"
                class="mt-2 p-4 border border-t-gray-400 flex-col flex items-center justify-between"
              >
                <div class="mb-4 lg:mb-0 text-left w-full">
                  <div class="flex justify-between items-center">
                    <div class="truncate">{{ wallet.wallet_address }}</div>
                    <div
                      v-if="wallet.wallet_address === user?.primary_wallet.wallet_address"
                      class="uppercase text-xs text-gray-400 text-left"
                    >
                      Primary
                    </div>
                  </div>
                  <div class="text-gray-400 text-sm">
                    <span v-if="wallet.ens_name">({{ wallet.ens_name }}) </span>
                    <span v-if="wallet.is_custodial">[CUSTODIAL] </span>
                  </div>
                </div>
                <div class="flex items-center w-full justify-between">
                  <div
                    v-if="!(wallet.wallet_address === user?.primary_wallet.wallet_address)"
                    @click="makePrimaryWallet(wallet.wallet_address)"
                    class="text-link"
                  >
                    Make Primary
                  </div>
                  <div
                    v-if="
                      !(wallet.wallet_address === user?.primary_wallet.wallet_address) &&
                      !wallet.is_custodial
                    "
                    @click="removeWallet(wallet.wallet_address)"
                    class="text-link text-link-red"
                  >
                    Remove Wallet
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="right-col">
          <div class="mb-4 pb-4 border-b border-gray-500">
            <h1>Authentication</h1>
          </div>

          <template v-if="user?.email">
            <div class="flex flex-col mb-8">
              <label class="text-gray-700 mb-1">Update Email</label>
              <div class="text-gray-500 text-sm italic mb-2">
                Change your account email address.
              </div>

              <form @submit.prevent="updateEmail" class="w-full">
                <div class="w-full mb-2">
                  <input
                    type="text"
                    v-model="updateEmailForm.email"
                    class="border-gray-400 border p-4 rounded w-full"
                    placeholder="Email address"
                    :disabled="!updateEmailForm.show"
                  />
                </div>
                <div class="flex">
                  <button
                    class="btn btn-sm btn-ch1red mr-4"
                    :disabled="loading"
                    type="button"
                    v-if="updateEmailForm.show"
                    @click="cancelEmailChange"
                  >
                    Cancel email change
                  </button>
                  <button
                    class="btn btn-sm"
                    :disabled="loading"
                    type="submit"
                    v-if="updateEmailForm.show"
                  >
                    Submit new email
                  </button>
                  <button
                    class="btn btn-sm"
                    :disabled="loading"
                    type="button"
                    @click="() => (updateEmailForm.show = true)"
                    v-else
                  >
                    Change email
                  </button>
                </div>
              </form>
            </div>

            <div class="flex flex-col mb-8">
              <label class="text-gray-700 mb-1">Update Password</label>
              <div class="text-gray-500 text-sm italic mb-2">Set a new password.</div>

              <template v-if="!updatePasswordForm.show">
                <div>
                  <button
                    class="btn btn-sm"
                    :disabled="loading"
                    type="button"
                    @click="() => (updatePasswordForm.show = true)"
                  >
                    Change Password
                  </button>
                </div>
              </template>

              <template v-else>
                <form @submit.prevent="updatePassword" class="w-full">
                  <div class="w-full mb-2">
                    <input
                      :type="updatePasswordForm.type"
                      v-model="updatePasswordForm.password"
                      class="border-gray-400 border p-4 rounded w-full"
                      placeholder="Current password"
                    />
                  </div>
                  <div class="w-full mb-2">
                    <input
                      :type="updatePasswordForm.type"
                      v-model="updatePasswordForm.password_new"
                      class="border-gray-400 border p-4 rounded w-full"
                      placeholder="New password"
                    />
                  </div>
                  <div class="mb-2">
                    <div
                      class="text-link"
                      @click="() => (updatePasswordForm.type = 'text')"
                      v-if="updatePasswordForm.type === 'password'"
                    >
                      Show passwords
                    </div>
                    <div
                      class="text-link"
                      @click="() => (updatePasswordForm.type = 'password')"
                      v-if="updatePasswordForm.type === 'text'"
                    >
                      Hide passwords
                    </div>
                  </div>
                  <div class="flex">
                    <button
                      class="btn btn-sm btn-ch1red mr-4"
                      :disabled="loading"
                      type="button"
                      @click="cancelPasswordChange"
                    >
                      Cancel password change
                    </button>

                    <button class="btn btn-sm" :disabled="loading" type="submit">
                      Submit new password
                    </button>
                  </div>
                </form>
              </template>
            </div>
          </template>
          <template v-else>
            <div class="flex flex-col mb-8">
              <label class="text-gray-700 mb-1">Setup email and password Email</label>
              <div class="text-gray-500 text-sm italic mb-2">
                Add email and password to your account as an additional way to log in
              </div>

              <form @submit.prevent="initialSetEmailPassword" class="w-full">
                <div class="w-full mb-2" v-if="initialSetForm.show">
                  <input
                    type="text"
                    v-model="initialSetForm.email"
                    class="border-gray-400 border p-4 rounded w-full"
                    placeholder="Email address"
                  />
                </div>

                <div class="w-full mb-2" v-if="initialSetForm.show">
                  <input
                    :type="initialSetForm.type"
                    v-model="initialSetForm.password"
                    class="border-gray-400 border p-4 rounded w-full"
                    placeholder="Password"
                  />
                </div>

                <div class="w-full mb-2" v-if="initialSetForm.show">
                  <div
                    class="text-link"
                    @click="() => (initialSetForm.type = 'text')"
                    v-if="initialSetForm.type === 'password'"
                  >
                    Show password
                  </div>
                  <div
                    class="text-link"
                    @click="() => (initialSetForm.type = 'password')"
                    v-if="initialSetForm.type === 'text'"
                  >
                    Hide password
                  </div>
                </div>

                <div class="flex">
                  <button
                    class="btn btn-sm btn-ch1red mr-4"
                    :disabled="loading"
                    type="button"
                    v-if="initialSetForm.show"
                    @click="cancelInitialSetEmailPassword"
                  >
                    Cancel setup
                  </button>
                  <button
                    class="btn btn-sm"
                    :disabled="loading"
                    type="submit"
                    v-if="initialSetForm.show"
                  >
                    Submit email/password
                  </button>
                  <button
                    class="btn btn-sm"
                    :disabled="loading"
                    type="button"
                    @click="() => (initialSetForm.show = true)"
                    v-else
                  >
                    Set up email and password
                  </button>
                </div>
              </form>
            </div>
          </template>

          <div class="flex flex-col mb-8 mt-8 pt-8 border-t">
            <label class="text-gray-700 mb-1">Get a login link</label>
            <div class="text-gray-500 text-sm italic mb-2">
              This will generate a one-time use login link you can send to yourself via
              email/text/whatever to allow you log in on another browser or device.
            </div>
            <div class="text-red-400 text-sm italic mb-2">
              NOTE: These codes are one-time use, will only work for 5 minutes, and generating a new
              one will disable any previous link
            </div>
            <div class="mb-2" v-if="!loginLink">
              <button class="btn btn-sm" @click="getLink" :disabled="loading">Generate link</button>
            </div>
            <InputCopyClipboard
              v-if="loginLink"
              :value="loginLink"
              @cancel="
                () => {
                  loginLink = null;
                }
              "
            />
            <!-- <input type="text" v-if="loginLink" :value="loginLink" disabled="disabled" class="border-gray-400 border p-4 rounded" /> -->
          </div>
        </div>
      </div>
    </div>
  </LoadUser>
</template>
